export default {
  state: {
    Config: "", // 接口返回的 配置项数据
    City: "",//接口返回的城市下拉 选择数据
    KeyWord: "",//搜索
    page: {
      ad: {
        top: false,
        topHeight: 60
      },
      marginTop: 30,
      menu: {
        height: 60
      }
    }
  },
  getters: {
    getConfig(state) {
      return state.Config
    },
    getCity(state) {
      return state.City
    },
    getKeyWord(state) {
      return state.KeyWord
    },
    getPageConfig(state) {
      return state.page
    },
    getStickyHeight(state) {
      let height = state.page.ad.top
        ? state.page.ad.topHeight + state.page.menu.height
        : state.page.menu.height
      height += state.page.marginTop
      return height + 'px'
    }
  },
  mutations: {
    setConfig(state, data) {
      state.Config = data
    },
    setCity(state, data) {
      state.City = data
    },
    setKeyWord(state, data) {
      state.KeyWord = data
    }
  },
  actions: {
    setConfig({ commit }, data) {
      commit('setConfig', data);
    },
    setCity({ commit }, data) {
      commit('setCity', data);
    },
    setKeyWord({ commit }, data) {
      commit('setKeyWord', data);
    }
  }
}