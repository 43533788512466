/*
 * @Description: 用户登录状态模块
 * @Author: hai-27
 * @Date: 2020-02-19 17:42:11
 * @LastEditors: hai-27
 * @LastEditTime: 2020-02-26 23:14:32
 */
export default {
  state: {
    user: "", // 登录的用户
    showLogin: false, // 用于控制是否显示登录组件
    showRegister: false,
  },
  getters: {
    getUser(state) {
      return state.user
    },
    getShowLogin(state) {
      return state.showLogin
    },
    getShowRegister(state) {
      return state.showRegister
    },
    getToken(state) {
      return state.token
    },
  },
  mutations: {
    setUser(state, data) {
      state.user = data;
    },
    setShowLogin(state, data) {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      state.showLogin = data;
    },
    setShowRegister(state, data) {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      state.showRegister = data;
    },
    setToken(state, data) {
      state.token = data
    },
    setAd(state, data) {
      state.ad = data
    },
    logout(state) {
      console.log('mutations logout')
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      this.setUser(state, '')
      this.setToken(state, '')
    }
  },
  actions: {
    setUser({ commit }, data) {
      commit('setUser', data);
    },
    setShowLogin({ commit }, data) {
      commit('setShowLogin', data);
    },
    setShowRegister({ commit }, data) {
      commit('setShowRegister', data);
    },
    setToken({ commit }, data) {
      commit('setToken', data);
    },
    setAd({ commit }, data) {
      commit('setAd', data);
    },
    logout({ commit }, data) {
      console.log('actions logout')
      commit('logout', data)
    }
  }
}
