//  混入模式
export default {
    // 计算
    computed: {
        // 转 平台 Name
        platformId() {
            return (ids, list) => {
                let platformArr = list || this.$store.getters.getConfig.servicePlatformList;
                if (!platformArr) return
                for (let x = 0; x < platformArr.length; x++) {
                    if (platformArr[x].id == ids) {
                        return platformArr[x].platformName
                    }
                }
            }
        },
        // 转 标签
        service_label() {
            return (label, max) => {
                if (!label) return
                return max ? label.split(",").slice(0, max) : label.split(",");
            }
        },
        providerLabel(value) {
            let serviceProviderLabels = this.$store.getters.getConfig.serviceProviderLabels;
            let tmp = serviceProviderLabels.find(item => item.value === value)
            return tmp ? tmp.label : ''
        },
        // 转服务 类目
        categories_id() {
            return (ids, list) => {
                let categoriesArr = list || this.$store.getters.getConfig.serviceCategories;
                if (!categoriesArr) return
                for (let x in categoriesArr) {
                    if (x == ids) {
                        return categoriesArr[x]
                    }
                }
            }
        },
        // 行业
        provider_industry() {
            return (ids) => {
                let Name
                let arr = this.$store.getters.getConfig.serviceItemsList;
                for (let x = 0; x < arr.length; x++) {
                    if (arr[x].id == ids) {
                        Name = arr[x].itemsName
                    }
                }
                return Name
            }
        },
        //
        // 时间转换
        formatDate() {
            return (d, type) => {
                var date = new Date(d);
                var YY = date.getFullYear() + '-';
                var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
                var DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
                var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
                var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
                var ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
                // + " " + hh + mm + ss
                let day
                if (type == 'day') {
                    day = YY + MM + DD
                } else {
                    day = YY + MM + DD + ' ' + hh + mm + ss;
                }
                return day
            };
        }
    },
}
