export default {
    LogoPath: '',
    Menu: [
        {
            name: '首页',
            routerName: 'Home',
        },
        {
            name: '服务大厅',
            routerName: 'ServiceHall',
        },
        {
            name: '加入赚钱',
            routerName: 'ProviderJoin',
        },
        {
            name: '寻单大厅',
            routerName: 'SeekingHall'
        },
        {
            name: '推荐服务商',
            routerName: 'Provider'
        },
        {
            name: '领劵中心',
            routerName: ''
        }
    ],
    Dropdown: [
        {
            name: '个人信息',
            routerName: 'UserHome'
        },
        {
            name: '我的订单',
            routerName: 'Order'
        },
        {
            name: '发布需求',
            routerName: 'SeekingHallPublish'
        },
        {
            name: '商家信息',
            routerName: 'ProviderInfo'
        },
        {
            name: '我的服务',
            routerName: 'ServeInfo'
        },
        {
            name: '发布服务',
            routerName: 'PublishingService'
        },
        {
            name: '服务订单',
            routerName: 'serveInfoOrder'
        },
        {
            name: '任务订单',
            routerName: 'DemandCollectionList'
        },
        {
            name: '资金管理',
            routerName: 'FundMent'
        },
    ]
}