// 底部导航栏 页面路由
// 导出
export default {
    TabBar: [
        {
            path: "/Guide",
            name: "Guide",
            component: () => import("@/views/TabBar/Guide.vue"), //新手指南
        },
        {
            path: "/Knowing",
            name: "Knowing",
            component: () => import("@/views/TabBar/Knowing.vue"), //注册 及 认证
        },
        {
            path: "/RuleCenter",
            name: "RuleCenter",
            component: () => import("@/views/TabBar/Rule.vue"), // 规则 中心
        },
        {
            path: "/Policy",
            name: "Policy",
            component: () => import("@/views/TabBar/Policy.vue"), // 隐私保护政策
        },
        {
            path: "/Account",
            name: "Account",
            component: () => import("@/views/TabBar/Account.vue"), // 账号安全
        },
        {
            path: "/Trade",
            name: "Trade",
            component: () => import("@/views/TabBar/Trade.vue"), // 交易安全
        },
        {
            path: "/Report",
            name: "Report",
            component: () => import("@/views/TabBar/Report.vue"), // 举报中心
        },
        {
            path: "/UserAgreement",
            name: "UserAgreement",
            component: () => import("@/views/TabBar/UserAgreement.vue"), // 平台用户协议
        },
        {
            path: "/ServiceFramework",
            name: "ServiceFramework",
            component: () => import("@/views/TabBar/ServiceFramework.vue"), // 平台服务框架
        }
    ],
};