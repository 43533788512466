export default {
    Seeking: [
        {
            path: "/SeekingHall",
            name: "SeekingHall",
            component: () => import("@/views/Seeking/index.vue"),
        },
        {
            path: "/SeekingHall/Detail",
            name: "SeekingHallDetail",
            component: () => import("@/views/Seeking/detail.vue"),
        },
        // 需求支付
        {
            path: "/Demand/Pay",
            name: "DemandPay",
            component: () => import("@/views/Seeking/payment.vue"),
            meta: {
                requireAuth: true // 需要验证登录状态
            },
        },
        {
            path: "/Demand/Buy",
            name: "DemandBuy",
            component: () => import("@/views/Seeking/buy.vue"),
            meta: {
                requireAuth: true // 需要验证登录状态
            },
        },
    ],
};