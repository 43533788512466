<template>

  <el-tooltip placement="top" effect="light" popper-class="tips">
    <div slot="content">上传图片不超过10MB</div>
    <i class="el-icon-picture-outline upload" @click="clickFile">
      <input type="file" class="hide" ref="imgDom" @change="pictureChange"/>
    </i>
  </el-tooltip>

</template>
<script>
import WebIM from '@/utils/WebIM/WebIM.js'
import { mapActions } from "vuex";
export default {
  name: 'UploadImage',
  data() {
    return {
    };
  },
  methods: {
    ...mapActions([
      'sendImgMessage',
    ]),
    pictureChange(e) {
      let file = WebIM.utils.getFileUrl(e.target);
      if (!file.filename) {
        this.$refs.imgDom.value = null;
        return false;
      }

      let payload = {
        chatType: 'contact',
        chatId: this.chatId,
        file: file,
        callback: this.send
      }

      let allowType = {
        jpg: true,
        jpeg: true,
        gif: true,
        png: true,
        bmp: true,
      };
      if (payload.file.filetype.toLowerCase() in allowType) {
        this.sendImgMessage(payload)
      }
    },
    clickFile() {
      let img = this.$refs.imgDom;
      img && img.click();
    },
    inputRef(node) {
      this.image = node;
    },
    send(val) {
      this.$emit("send", val);
    }
  },
  props: [
    'type', // 聊天类型 contact, group, chatroom
    'chatId' // 选中的聊天对象
  ]
};
</script>
<style scoped lang="less">
.upload {
  cursor: pointer;
}

.hide {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  filter: alpha(opacity=0);
  opacity: 0;
  cursor: inherit;
  display: none;
}
</style>