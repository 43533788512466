export default {
    Menu: [
        {
            name: '服务与支持',
            chind: [
                {
                    name: '新人指南'
                },
                {
                    name: '新人福利'
                },
                {
                    name: '注册及认证',
                    router: "/Knowing"
                },
            ]
        },
        {
            name: '规则/反馈',
            chind: [
                {
                    name: '规则中心',
                    router: "/RuleCenter"
                },
                {
                    name: '信息公示区',
                    router: "/News"
                },
                {
                    name: '隐私保护政策',
                    router: "/Policy"
                },
                {
                    name: '举报中心',
                    router: '/Report'
                }
            ]
        },
        {
            name: '安全中心',
            chind: [
                {
                    name: '账号安全',
                    router: '/Account'
                },
                {
                    name: '交易安全',
                    router: '/Trade'
                },
                {
                    name: '数据及信息'
                },
            ]
        }, {
            name: '贴心服务',
            chind: [
                {
                    name: '帮助中心'
                },
                {
                    name: '在线客服'
                },
                {
                    name: '平台用户协议',
                    router: '/UserAgreement'
                },
                {
                    name: '平台服务框架',
                    router: '/ServiceFramework'
                }
            ]
        }, {
            name: '关于雇人用',
            chind: [
                {
                    name: '招贤纳士'
                },
                {
                    name: '联系我们'
                }
            ]
        }
    ]
}