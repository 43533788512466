/*
 * @Description: 路由配置
 * @Author: hai-27
 * @Date: 2020-02-07 16:23:00
 * @LastEditors: hai-27
 * @LastEditTime: 2020-02-27 13:58:48
 */
import Vue from "vue";
import Router from "vue-router";
// 订单模块
import Order from "./Order";
// 新闻模块
import News from "./News";
// 服务商 模块
import Service from "./Service";
// 底部导航栏
import TabBar from "./TabBar";
import Seeking from "./Seeking";

Vue.use(Router);

const routes = [
    ...Order.Order, //订单模块
    ...News.News,//新闻 模块
    ...Service.Service,//服务商 模块
    ...TabBar.TabBar,//底部导航栏
    ...Seeking.Seeking, //寻单大厅
    {
        path: "/login",
        name: "login",
        component: () => import("../views/login.vue"),
    },
    {
        path: "/forgot",
        name: "forgotPassword",
        component: () => import("../views/forgotPassword.vue"),
    },

    {
        path: "/index",
        name: "Home",
        component: () => import("../views/home/index.vue"),
    },
    {
        path: "/error",
        name: "Error",
        component: () => import("../components/Error.vue"),
    },
    {
        path: "/", //服务大厅
        name: "ServiceHall",
        component: () => import("../views/ServiceHall.vue"),
    },
    {
        path: "/publishingService", //发布服务
        name: "PublishingService",
        component: () => import("../views/PublishingService.vue"),
        meta: {
            requireAuth: true // 需要验证登录状态
        }
    },
    {
        path: "/enterpriseSettlement", //服务商入驻
        name: "EnterpriseSettlement",
        component: () => import("../views/EnterpriseSettlement.vue"),
    },
    {
        path: "/UserInfo", //个人信息
        name: "UserInfo",
        redirect: "/UserInfo/PersonInfo",
        component: () => import("../views/UserInfo"),
        meta: {
            requireAuth: true // 需要验证登录状态
        },
        children: [
            {
                path: "UserHome",
                name: "UserHome",
                component: () => import("../views/UserInfo/components/UserHome.vue"),
                meta: {
                    requireAuth: true // 需要验证登录状态
                },
            },
            {
                path: "PersonInfo",
                name: "PersonInfo",
                component: () => import("../views/UserInfo/components/personInfo.vue"),
                meta: {
                    requireAuth: true // 需要验证登录状态
                },
            },
            {
                path: "Order",
                name: "Order",
                component: () => import("../views/UserInfo/components/Order.vue"),
                meta: {
                    requireAuth: true // 需要验证登录状态
                },
            },
            {
                path: "providerInfo",
                name: "ProviderInfo",
                component: () =>
                    import("../views/UserInfo/components/providerInfo.vue"),
                meta: {
                    requireAuth: true // 需要验证登录状态
                },
            },
            {
                path: "serveInfo",
                name: "ServeInfo",
                component: () => import("../views/UserInfo/components/serveInfo.vue"),
                meta: {
                    requireAuth: true // 需要验证登录状态
                },
            },
            {
                path: "serveInfoIndent",
                name: "ServeInfoIndent",
                component: () =>
                    import("../views/UserInfo/components/serveInfoIndent.vue"),
                meta: {
                    requireAuth: true // 需要验证登录状态
                },
            },

            {
                path: "serveInfoOrder",
                name: "serveInfoOrder",
                component: () =>
                    import("../views/UserInfo/components/providerOrder.vue"),
                meta: {
                    requireAuth: true // 需要验证登录状态
                },
            },
            {
                path: "demandInfo",
                name: "DemandInfo",
                component: () => import("../views/UserInfo/components/demandInfo.vue"),
                meta: {
                    requireAuth: true // 需要验证登录状态
                },
            },
            {
                path: "serveDetail",
                name: "ServeInfoDetail",
                component: () => import("../views/UserInfo/components/serveDetail.vue"),
                meta: {
                    requireAuth: true // 需要验证登录状态
                },
            },

            {
                path: "FundMent",
                name: "FundMent",
                component: () => import("../views/UserInfo/components/FundMent.vue"),
                meta: {
                    requireAuth: true // 需要验证登录状态
                },
            },
            // 发布需求
            {
                path: "/Demand/Publish",
                name: "SeekingHallPublish",
                component: () => import("@/views/Seeking/publish.vue"),
                meta: {
                    requireAuth: true // 需要验证登录状态
                },
            },

            // 已发布需求列表
            {
                path: "Demand",
                name: "PublishedDemandList",
                component: () => import("@/views/Seeking/list.vue"),
                meta: {
                    requireAuth: true // 需要验证登录状态
                },
            },
            // 收藏需求列表，已报名需求
            {
                path: "DemandCollection",
                name: "DemandCollectionList",
                component: () => import("@/views/Seeking/collection.vue"),
                meta: {
                    requireAuth: true // 需要验证登录状态
                },
            },
        ],
    },
    {
        path: "/ServiceHall/Details", //服务大厅 详情
        name: "ServiceHallDetails",
        component: () => import("../views/ServiceHallDetails.vue"),
    },
    {
        path: "/ProviderHall", //推荐服务商
        name: "Provider",
        component: () => import("../views/Provider/index.vue"),
    },
    {
        path: "/Provider/Join",   // 未入驻
        name: "ProviderJoin",
        component: () => import("@/views/Provider/join.vue"),
        meta: {
            requireAuth: true // 需要验证登录状态
        },
    },
    {
        path: "/Provider/Joined",   // 已入驻
        name: "ProviderJoined",
        component: () => import("@/views/Provider/joined.vue"),
        meta: {
            requireAuth: true // 需要验证登录状态
        },
    },
    {
        path: "/Chat", //对话
        name: "Chat",
        component: () => import("../components/Chat/index.vue"),
    },
    {
        path: "/Post",
        name: "post",
        component: () => import("@/views/TabBar/post.vue"),
    }
];

const router = new Router({
    // base: '/dist',
    // mode: 'history',
    routes,
    // 页面 切换 重置 滚动条
    scrollBehavior: () => ({ y: 0 }),
});

/* 由于Vue-router在3.1之后把$router.push()方法改为了Promise。所以假如没有回调函数，错误信息就会交给全局的路由错误处理。
vue-router先报了一个Uncaught(in promise)的错误(因为push没加回调) ，然后再点击路由的时候才会触发NavigationDuplicated的错误(路由出现的错误，全局错误处理打印了出来)。*/
// 禁止全局路由错误处理打印
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
        return originalPush.call(this, location, onResolve, onReject);
    return originalPush.call(this, location).catch((err) => err);
};

export default router;
