<template>
  <div class="NavMenu" :style="{ marginLeft: Left + 'px' }">
    <div v-for="(item, index) in Menu" :key="index">
      <router-link :to="{name : item.routerName}" :class="index === MenuIndex ? 'router-link-exact-actives' : ''">
        {{ item.name }}
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      Menu: [],
      MenuIndex: 0,
      Rourer: "",
    };
  },
  props: {
    Left: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    $route(to) {
      // 判断页面是否含有 相同路径的
      for (let x = 0; x < this.Menu.length; x++) {
        if (this.Menu[x].routerName && this.Menu[x].routerName === to.name) {
          this.MenuIndex = x;
          break
        } else {
          this.MenuIndex = -1;
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
.NavMenu {
  display: flex;
  align-items: center;

  a {
    color: #888888;
    font-size: 16px;
    padding: 0 18px;

    &:hover {
      color: #fc3737 !important;
      font-weight: bold;
    }
  }
}

// .router-link-active {
//     color: #FC3737;
// }

.router-link-exact-actives {
  color: #fc3737 !important;
  font-weight: bold;
}
</style>