<template>
  <div class="Foot">
    <div class="foot">
      <div class="item" v-for="(item, index) in datas.Menu" :key="index">
        <div class="Name">{{ item.name }}</div>
        <div class="Chind">
          <div class="items" v-for="(items, indexs) in item.chind" :key="indexs" @click="GetTabbar(items)">{{ items.name }}</div>
        </div>
      </div>
    </div>
    <div class="ext-link-container" v-if="extLinks && extLinks.length">
      <span>友情链接：</span>
      <a  v-for="(item, index) in extLinks" :key="index" :href="item.url" target="_blank">{{ item.name }}</a>
    </div>

    <div style="text-align: center;margin:0 auto; padding:20px 0;">
      <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=34160202000878" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
        <img src="@/assets/imgs/domain.jpg" style="float:left;" />
        <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">皖公网安备 34160202000878号</p>
      </a>
    </div>
    <div class="Filingnumber">
      <a href="https://beian.miit.gov.cn/" target="_blank">2023-2025 gurenyong.com copyright all right reserved 安徽拇指跳跃信息科技有限公司 (皖ICP备2023011473号-1)</a>
    </div>
  </div>
</template>
<script>
import datas from "./config";
export default {
  data() {
    return {
      datas,
      extLinks: [
        {name: '法律援助-荔法网', url: 'https://www.lifa345.com/'},
      ]
    };
  },
  methods: {
    GetTabbar(item) {
      this.$router.push({ path: item.router });
    },
  },
};
</script>
<style lang="less" scoped>
.Foot {
  width: 100%;
  padding-top: 30px;
  // min-width: 1920px;
  background: #ffffff;
  border-top: 1px solid #e1e1e1;

  .foot {
    width: 1440px;
    margin: 0 auto 30px auto;

    display: flex;
    justify-content: space-around;

    .item {
      margin: 0 30px;
    }

    .Name {
      color: #000000;
      font-size: 20px;
      // text-align: center;
      text-align: left;
      cursor: pointer;
    }

    .Chind {
      margin-top: 8px;

      .items {
        color: #999999;
        font-size: 16px;
        margin-top: 15px;
        cursor: pointer;
        // text-align: center;
        text-align: left;
        &:hover {
          color: #fc3737;
        }
      }
    }
  }

  .divider-line {
    width: 1260px;
    margin: 0 auto;
    height: 1px;
    background-color: #e1e1e1;
  }
  .ext-link-container {
    width: 1260px;
    margin: 20px auto;
    color: #999999;
    font-size: 14px;
    display: flex;
    padding: 20px 0;
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
    a {
      text-decoration: none;
      color: #000000;
      &:not(:nth-last-of-type(1)) {
        margin-right: 20px;
      }
    }
  }

  .Filingnumber {
    text-align: center;
    padding: 0 0 30px;
    div {
      margin: 0 20px;
      color: #000000;
      &:hover {
        color: #fc3737;
      }
    }
    a {
      color: #000000;
      &:hover {
        color: #fc3737;
      }
    }
  }
}
</style>