/*
 * @Description: Vuex入口
 * @Author: hai-27
 * @Date: 2020-02-07 16:23:00
 * @LastEditors: hai-27
 * @LastEditTime: 2020-02-25 22:51:50
 */
import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user'
// import shoppingCart from './modules/shoppingCart'
import config from "./modules/config"
import chat from "./modules/chat"
import query from "./modules/query"
Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  modules: {
    user,
    // shoppingCart,
    config,
    chat,
    query,
  },
})
