export default {
  state:     {
    serviceHall: {
      query: {
        title: '',
        execute: false,
      }
    }
  },
  getters:   {
    getServiceHallQueryTitle(state) {
      return state.serviceHall.query.title
    },
    getServiceHallQueryExecutable(state) {
      return state.serviceHall.query.execute
    }
  },
  mutations: {
    setServiceHallQueryTitle(state, data) {
      state.serviceHall.query.title = data;
    },
    doServiceHallQueryTitle(state) {
      state.serviceHall.query.execute = true;
    },
    resetServiceHallQueryExecutable(state) {
      state.serviceHall.query.execute = false;
    },
    clearServiceHallQueryTitle(state) {
      state.serviceHall.query.title = '';
    },
  },
  actions:   {
    setServiceHallQueryTitle({ commit }, data) {
      commit('setServiceHallQueryTitle', data);
    },
  }
}
