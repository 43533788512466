<template>
  <div class="Search">
    <!-- 输入框 -->
    <div class="Input">
      <el-input v-model="title" :placeholder="placeholder" clearable @keyup.enter.native="GetKeyWord"></el-input>
    </div>
    <!-- 搜索按钮 -->
    <div class="SearchTo" @click="GetKeyWord">
      <img src="../../assets/imgs/search.png" alt />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // Keyword: "",
      placeholder: "请输入关键词",
    };
  },
  props: {
    Left: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    title: {
      get() {
        return this.$store.getters.getServiceHallQueryTitle;
      },
      set(newValue) {
        this.$store.commit("setServiceHallQueryTitle", newValue);
      },
    }
  },
  methods: {
    // 搜索关键词
    GetKeyWord() {
      if (this.$route.name === 'ServiceHall') this.$store.commit('doServiceHallQueryTitle')
      else {
        // 跳转到服务大厅
        this.$router.push({
          name: "ServiceHall",
          params: {
            type: 'serviceTitle',
            title: this.title
          }
        });
      }
      
    },
  },
};
</script>
<style lang="less" scoped>
.Search {
  width: 423px;
  height: 53px;
  border-radius: 4px 4px 4px 4px;
  border: 2px solid #fc3737;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin: 0 auto;

  .Input {
    width: calc(100% - 110px);
    height: 100%;
  }
  /deep/ .el-input {
    height: 100%;
  }
  /deep/ .el-input {
    height: 100%;
  }

  /deep/ .el-input__inner {
    height: 100%;
    border: none;
  }
}
/deep/ .el-input__inner {
  height: 100%;
  border: none;
}

.SearchTo {
  width: 110px;
  height: 100%;
  background: #fc3737;
  border-radius: 0px 2px 2px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    width: 30px;
    height: 30px;
  }
}
</style>
