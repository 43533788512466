// 新闻 详情 页面路由
// 导出
export default {
    News: [
        {
            path: "/News",
            name: "News",
            component: () => import("@/views/News/index.vue"),
        },
        {
            path: "/News/Details",
            name: "NewsDetails",
            component: () => import("@/views/News/Details.vue"),
        },
    ],
};