<!--
 * @Description: 项目根组件
 * @Author: hai-27
 * @Date: 2020-02-07 16:23:00
 * @LastEditors: hai-27
 * @LastEditTime: 2020-04-05 13:14:48
 -->
<template>
  <div id="app" name="app">
    <el-container>
      <!-- 顶部导航栏 -->
      <!--<LoginRegister></LoginRegister>-->

      <!-- 底栏容器 -->

      <!-- 底栏容器END -->
    </el-container>
    <!-- 顶部导航栏 -->
    <Heards :HeardsShow="HeardsShow"></Heards>
    <!-- 顶部导航栏END -->
    <!-- 主要区域容器 -->
    <el-main class="el_main main" :style="{margin: margin + 'px auto'}">
      <!-- <keep-alive> -->
      <router-view></router-view>
      <chat-dialog></chat-dialog>
      <!--<span class="chat-tips" v-show="logged" @click="showChatDialog">-->
      <!--  <i class="el-icon-chat-dot-round"></i>-->
      <!--  <span class="unread" v-if="unread">{{ unread }}</span>-->
      <!--</span>-->
      <!-- </keep-alive> -->
    </el-main>
    <!-- 主要区域容器END -->
    <el-backtop :bottom="100" :right="50"></el-backtop>
    <!-- 底部栏目 -->
    <Foot v-show="!isLogin"></Foot>
    <!-- 底部栏目END -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Heards from "@/components/Heads/index.vue";
import Foot from "@/components/Foot/index.vue";
import ChatDialog from "@/components/Chat/index.vue";

export default {
  beforeUpdate() {
    this.activeIndex = this.$route.path;
  },
  data() {
    return {
      activeIndex: "", // 头部导航栏选中的标签
      search: "", // 搜索条件
      register: false, // 是否显示注册组件
      visible: false, // 是否退出登录
      HeardsShow: true, //头部是否显示
    };
  },
  components: {
    ChatDialog,
    Heards,
    Foot,
  },
  created() {
    if (
        !(location.host.startsWith('localhost')
            || location.host.startsWith('www.gurenyong.com')
        )
    ) {
      window.location.replace('http://www.gurenyong.com')
      return
    }
    // 获取浏览器localStorage，判断用户是否已经登录
    if (localStorage.getItem("user")) {
      // 如果已经登录，设置vuex登录状态
      let user = JSON.parse(localStorage.getItem("user"));
      this.setUser(user);

      // 登录环信
      let options = {
        username: user.userPhone,
        password: user.userPhone,
      };

      this.onLogin(options)
          .then(() => this.onGetContacts())
    }
    // 缓存走一走 活到99
    this.GetConfig();
    this.GetCity();
    // this.zoom();

  },
  mounted() {
    // window.addEventListener("resize", this.zoom, true);
  },
  computed: {
    ...mapGetters(["getUser", "getNum", 'getChatTips']),
    unread() {
      const _this = this;
      let contacts = _this.$store.getters.contactBook;
      let unread = 0
      contacts.forEach(item => {
        unread += item.unread
      })
      if (unread > 99) unread = '99'
      return unread;
    },
    logged() {
      return this.$store.getters.getUser && this.$store.getters.getUser.id;
    },
    isLogin() {
      return this.$route.name === 'login'
    },
    margin() {
      return this.$store.getters.getPageConfig.marginTop
    }
  },
  watch: {
    // 获取vuex的登录状态
    // getUser: function (val) {
    //   if (!val.id) {
    //   } else {
    //   }
    // },
  },
  methods: {
    ...mapActions([
      "setUser",
      "setToken",
      "setShowLogin",
      "setConfig",
      "setCity",
      "onLogin",
      "onGetContacts",
      'showChat',
      'sendChannelRead',
    ]),
    getContactInfo() {

    },
    login() {
      // 点击登录按钮, 通过更改vuex的showLogin值显示登录组件
      this.setShowLogin(true);
    },
    // 退出登录
    logout() {
      this.visible = false;
      // 清空本地登录信息
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      // 清空vuex登录信息
      this.setUser("");
      this.setToken("");
      this.notifySucceed("成功退出登录");
    },
    // 接收注册子组件传过来的数据
    isRegister(val) {
      this.register = val;
    },
    // 点击搜索按钮
    searchClick() {
      if (this.search != "") {
        // 跳转到全部商品页面,并传递搜索条件
        this.$router.push({ path: "/goods", query: { search: this.search } });
        this.search = "";
      }
    },
    // 获取 全局配置项目
    GetConfig() {
      this.$axios
          .get(`/app-api/hiring/config/getConfig`)
          .then((res) => {
            if (res.data.code === 0) {
              this.setConfig(res.data.data);
              // localStorage.setItem("config", res.data.data);
            }
          })
          .catch((err) => {
            return Promise.reject(err);
          });
    },
    // 获取省市区
    GetCity() {
      this.$axios
          .get(`/app-api/hiring/area/cityTree`)
          .then((res) => {
            if (res.data.code === 0) {
              this.setCity(res.data.data);
            }
          })
          .catch((err) => {
            return Promise.reject(err);
          });
    },
    // 获取行业
    getIndustryOptions() {
    },
    zoom() {
      //  当 clientWidth 小于1440 时
      let clientWidth = document.body.clientWidth;
      let domBody = document.getElementsByTagName("body")[0];
      if (clientWidth < 1440) {
        domBody.style.zoom = "100%";
        domBody.style.zoom = clientWidth / 19.2 + "%";
      } else {
        domBody.style.zoom = "100%";
      }
    },
    showChatDialog() {
      let _this = this
      _this.showChat({
        show: true,
        phone: _this.getChatTips.phone,
      })
    },
  },
};
</script>

<style lang="less">
/* 引入 iconfont 图标 */
@import "./assets/css/iconfont/iconfont.css";
.chat-tips {
  position: fixed;
  bottom: 50px;
  right: 50px;
  cursor: pointer;
  &:hover {
    filter: brightness(0.9);
  }
  i {
    font-size: 30px;
  }
  .unread {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #ff0000;
    border-radius: 50%;
    color: #ffffff;
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    text-align: center;
  }
}
#app {
  background: #f7f9fa
}
main.main {
  width: 1440px;
}

.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}

/* 全局CSS */
* {
  padding: 0;
  margin: 0;
  border: 0;
  list-style: none;
}

input {
  border: 0; /*清除自带的2px的边框*/
  padding: 0; /*清除自带的padding间距*/
  outline: none; /*清除input点击之后的黑色边框*/
}

#app .el-header {
  padding: 0;
}

#app .el_main {
  min-height: 300px;
  padding: 0 !important;
  overflow: inherit !important;
  // min-width: 1920px;
  // min-height: 430px;
}

#app .el-footer {
  padding: 0;
}

a,
a:hover {
  text-decoration: none;
}

/* 全局CSS END */

/* 顶部导航栏CSS */
.topbar {
  height: 40px;
  background-color: #3d3d3d;
  margin-bottom: 20px;
}

.topbar .nav {
  max-width: 1225px;
  margin: 0 auto;
}

.topbar .nav ul {
  float: right;
}

.topbar .nav li {
  float: left;
  height: 40px;
  color: #b0b0b0;
  font-size: 14px;
  text-align: center;
  line-height: 40px;
  margin-left: 20px;
}

.topbar .nav .sep {
  color: #b0b0b0;
  font-size: 12px;
  margin: 0 5px;
}

.topbar .nav li .el-button {
  color: #b0b0b0;
}

.topbar .nav .el-button:hover {
  color: #fff;
}

.topbar .nav li a {
  color: #b0b0b0;
}

.topbar .nav a:hover {
  color: #fff;
}

.topbar .nav .shopCart {
  width: 120px;
  background: #424242;
}

.topbar .nav .shopCart:hover {
  background: #fff;
}

.topbar .nav .shopCart:hover a {
  color: #ff6700;
}

.topbar .nav .shopCart-full {
  width: 120px;
  background: #ff6700;
}

.topbar .nav .shopCart-full a {
  color: white;
}

/* 顶部导航栏CSS END */

/* 顶栏容器CSS */
.el-header .el-menu {
  max-width: 1225px;
  margin: 0 auto;
}

.el-header .logo {
  height: 60px;
  width: 189px;
  float: left;
  margin-right: 100px;
}

.el-header .so {
  margin-top: 10px;
  width: 300px;
  float: right;
}

/* 顶栏容器CSS END */
.el-avatar img {
  width: 100%;
}
</style>
