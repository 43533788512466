<template>
  <div class="User">
    <el-dropdown @command="GetPushRouter">
      <span class="el-dropdown-link">
        <el-badge :value="unread" :max="99" class="item" :hidden="unread <= 0">
          <el-avatar :size="40" :src="circleUrl"></el-avatar>
        </el-badge>
        
        <i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-badge :is-dot="unread > 0" class="dropdown-item-badge">
          <el-dropdown-item class="dropdown-item" command="showChatDialog">我的消息</el-dropdown-item>
        </el-badge>
        <el-dropdown-item v-for="(item, index) in Dropdown" :key="index" :command="item.routerName" class="dropdown-item">
          {{ item.name }}
        </el-dropdown-item>
        <!-- 退出登录 -->
        <el-dropdown-item divided class="dropdown-item" command="login">退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      Dropdown: [],
      circleUrl: "",
    };
  },
  computed: {
    
    unread() {
      const _this = this;
      let contacts = _this.$store.getters.contactBook;
      let unread = 0
      contacts.forEach(item => {
        unread += item.unread
      })
      // if (unread > 99) unread = '99'
      return unread;
    },
  },
  methods: {
    ...mapActions(["setToken", "setUser", 'onLogout', 'showChat']),
    GetPushRouter(router) {
      if (router === "login") {
        this.GetOutLogin();
      } else if (router === 'showChatDialog') {
        this.showChatDialog()
      } else {
        this.$router.push({name: router});
      }
      
    },
    //退出登录
    GetOutLogin() {
      let url = "/app-api/hiring/auth/logout";
      this.$axios
        .post(url)
        .then((res) => {
          if (res.data.code === 0) {
            // 清除缓存数据
            this.setToken({});
            this.setUser({});
            localStorage.clear();
            this.$router.push({ path: "/" });
            this.notifySucceed("退出成功！");
            // 退出环信
            this.onLogout()
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    showChatDialog() {
      let _this = this
      _this.showChat({
        show: true,
        phone: _this.$store.getters.getChatTips.phone,
      })
    },
  },
};
</script>
<style lang="less">
.el-dropdown-link {
  display: flex;
  align-items: center;
}
.dropdown-item {
  text-align: center;
}
.dropdown-item-badge sup {
  top: 8px !important;
  right: 22px !important;
}
</style>
