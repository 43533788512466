<template>
  <el-dialog :visible="chat.show" @close="closeChatDialog" top="0" width="40%" :close-on-click-modal="false"
             custom-class="yan-chat">
    <div slot="title">
      <div class="title-name">{{ activatedContact.nickName }}</div>
    </div>

    <div class="talk">
      <!-- region 左侧联系人开始 -->
      <div class="contact-container">
        <div class="contact-item-container" :class="activatedContact.userPhone === item.userPhone ? 'active' : ''" @click="clickContact(item)"
             v-for="(item, index) in contacts" :key="index">
          <img alt="" class="contact-avatar" :src="item.avatarImg" />
          <div class="contact-content-container">
            <div class="contact-name">{{ item.nickName || item.userPhone }}</div>
            <div class="contact-last-message">{{ item.msg }}</div>
            <div class="contact-last-message-time">{{ item.readtime }}</div>
          </div>
          <span class="unread" v-if="item.unread">{{ item.unread }}</span>
        </div>
      </div>
      <!-- endregion 左侧联系人结束 -->
      
      <div class="content-container">
        <div class="talk-content" ref="scrollDiv">
          <!-- region 发送商品开始 -->
          <div class="goods" v-if="chat.goods && chat.goods.name">
            <div class="goods-container">
              <img :alt="chat.goods.name" class="goods-image" :src="chat.goods.img"/>
              <div class="goods-info-container">
                <div class="goods-name">{{ chat.goods.name }}</div>
                <div class="goods-desc">{{ chat.goods.desc }}</div>
              </div>
            </div>
            <div class="divider"></div>
            <el-button class="goods-button" type="danger" round @click="sendGoods">发送链接</el-button>
          </div>
          <!-- endregion 发送商品结束 -->
          <div v-for="(item, index) in msgList" :key="index" style="margin-top: 20px">
            <div class="message-item-time-container">
              <p class="message-item-time"> {{ renderTime(item.time) }}</p>
            </div>
            <div class="message-item-container" :class="item.bySelf ? 'right-container' : ''">
              <div class="message-item-avatar-container">
                <el-avatar shape="circle" :size="30" :src="item.bySelf ? self.avatarImg : activatedContact.avatarImg"></el-avatar>
              </div>
              <div class="message-item-content-container">
                <p class="message-item-name" :class="item.bySelf ? 'right-name' : ''"> {{ item.bySelf ? self.nickName : activatedContact.nickName }} </p>
                <div class="message-item-content" v-if="item.type === 'txt'" :class="item.bySelf ? 'right-content' : ''" v-html="item.msg"></div>
                <div class="message-item-content" v-if="item.type === 'img'" :class="item.bySelf ? 'right-content' : ''">
                  <img :src="item.msg" alt="" class="message-image"/>
                </div>
                <div class="message-item-content" v-if="item.type === 'file'" :class="item.bySelf ? 'right-content' : ''">
                  <div class="message-file-container">
                    <div class="file-info">
                      <div class="filename">{{ item.filename }}</div>
                      <div class="filesize">{{ readablizeBytes(item.file_length) }}</div>
                    </div>
                    <a :href="item.msg" :download="item.filename" class="file-download">点击下载</a>
                  </div>
                </div>
                <div class="message-item-content" v-if="item.type === 'goods'" :class="item.bySelf ? 'right-content' : ''"
                     @click="showGoods(item.msg.id)">
                  <div class="goods-container">
                    <img :alt="item.msg.name" class="goods-image" :src="item.msg.img"/>
                    <div class="goods-info-container">
                      <div class="goods-name">{{ item.msg.name }}</div>
                      <div class="goods-desc">{{ item.msg.desc }}</div>
                    </div>
                  </div>
                </div>
                <div class="message-status" v-if="item.bySelf">{{ status[item.status] }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="talk-message">
          <div class="talk-message-option-container">
            <div class="talk-message-face">
              <svg class="face-icon" aria-hidden="true" @click="emotionIsShow = !emotionIsShow">
                <use xlink:href="#icon-biaoqing"></use>
              </svg>
              <emotion :emotionIsShow="emotionIsShow" @sendEmotionSelect="getValue"></emotion>
            </div>
            <uploadImage type="contact" :chatId="activatedContact.phone" @send="scrollToBottom"/>
            <uploadFile type="contact" :chatId="activatedContact.phone" @send="scrollToBottom"/>
          </div>

          <div class="talk-message-content">
            <el-input v-model="textarea" resize="none" type="textarea" rows="1" @keyup.enter.native="submit"></el-input>
          </div>
          <div class="talk-message-send">
            <el-button type="text" plain @click="submit">发送</el-button>
<!--            <el-button type="text" plain @click="deletemessage">删除</el-button>-->
          </div>
        </div>
      </div>
    </div>

  </el-dialog>
</template>

<script>
import { mapActions } from "vuex";
import emotion from "./emotion.vue";
import uploadImage from "./uploadImage.vue";
import uploadFile from "./uploadFile.vue";
import './iconfont';
import moment from "moment";

export default {
  name: 'ChatDialog',
  components: {
    emotion,
    uploadImage,
    uploadFile
  },
  data() {
    return {
      url: "",
      eList: ['占位', '微笑', '撇嘴', '色', '发呆', '得意', '流泪', '害羞', '闭嘴', '睡', '大哭',
        '尴尬', '发怒', '调皮', '呲牙', '惊讶', '难过', '酷', '冷汗', '抓狂', '吐', '偷笑', '可爱',
        '白眼', '傲慢', '饥饿', '困', '惊恐', '流汗', '憨笑', '大兵', '奋斗', '咒骂', '疑问', '嘘',
        '晕', '折磨', '衰', '骷髅', '敲打', '再见', '擦汗', '抠鼻', '鼓掌', '糗大了', '坏笑', '左哼哼',
        '右哼哼', '哈欠', '鄙视', '委屈', '快哭了', '阴险', '亲亲', '吓', '可怜', '菜刀', '西瓜', '啤酒',
        '篮球', '乒乓', '咖啡', '饭', '猪头', '玫瑰', '凋谢', '示爱', '爱心', '心碎', '蛋糕', '闪电', '炸弹',
        '刀', '足球', '瓢虫', '便便', '月亮', '太阳', '礼物', '拥抱', '强', '弱', '握手', '胜利', '抱拳', '勾引',
        '拳头', '差劲', '爱你', 'NO', 'OK', '爱情', '飞吻', '跳跳', '发抖', '怄火', '转圈', '磕头', '回头', '跳绳', '挥手',
        '激动', '街舞', '献吻', '左太极', '右太极'],
      contentDiv: [],
      textarea: "",
      textarea1: "",
      emotionIsShow: false, // 表情面板控制出现
      divIptEmotion: [], // 接收表情组件传过来的数据
      a: [],
      show: false,
      closeChat: this.close,
      status: {
        sending: '发送中',
        sent: '已发送',
        read: '已读',
      },
      config: {},
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    ...mapActions([
      'hideChat',
      'onSendText',
      'sendCustomMessage',
      'deleteMessage',
      'deleteConversation',
      'getHistoryMessage',
      'sendChannelRead',
    ]),
    deletemessage() {
      this.deleteConversation({
        chatType: 'contact',
        chatId: this.activatedContact.phone,
        callback: (res) => {console.log(res)},
        error: (res) => {console.log(res)},
      })
    },
    renderTime(time) {
      return moment(+time).format('YYYY-MM-DD HH:mm:ss')
    },
    /**
     * 关闭对话窗，取消环信事件监听、关闭环信连接、对话信息清空
     */
    closeChatDialog() {
      // this.logoutWebIM()
      this.hideChat();
    },
    scrollToBottom() {
      let _this = this
      _this.$nextTick(() => {
        let scrollElem = _this.$refs.scrollDiv;
        if (!scrollElem) return
        scrollElem.scrollTo({ top: scrollElem.scrollHeight, behavior: 'smooth' });
      })
    },

    getValue(val, item) {
      let src = require('@/assets/imgs/emoji/' + val + '.png');
      let img = '<img src="' + src + 'style= "width: 22px;height: 22px">';
      // console.log(img)
      let el = '[' + item + ']'
      this.emotionIsShow = false;
      this.textarea = this.textarea + el;
      this.textarea1 += img;
    },
    submit() {
      if (!this.textarea.trim()) {
        this.$message.info('内容不能为空');
        return
      }
      let a = this.textarea;
      let pre = 0;
      let last = 0;
      // eslint-disable-next-line no-unused-vars
      let flag = false;
      // eslint-disable-next-line no-constant-condition
      while (true) {
        if (!a.match("]")) {
          break;
        }
        pre = a.indexOf("[");
        last = a.indexOf("]");

        let face = a.substring(pre + 1, last);
        let b;
        for (let j = 0; j < this.eList.length; j++) {
          if (face == this.eList[j]) {
            b = j;
            break;
          }
        }
        let src = require('@/assets/imgs/emoji/' + b + '.png');
        let img = '<img src="' + src + '" class= "emoji">';
        a = a.substring(0, pre) + img + a.substring(last + 1, a.length);
        pre = 0;
        last = 0;
        flag = false;
      }

      this.textarea = "";
      this.textarea1 = "";


      // this.sendMessageWebIM(this.chat.phone, a, 'txt')
      this.onSendText({
        chatType: 'contact',
        chatId: this.activatedContact.userPhone,
        message: a,
        callback: this.scrollToBottom
      })
    },

    exit() {
      this.$emit('close', this.fleg)
    },

    sendGoods() {
      let message = {
        ...this.chat.goods,
        type: 'goods'
      }
      this.sendCustomMessage({
        chatType: 'contact',
        chatId: this.activatedContact.userPhone,
        message: message,
        callback: this.scrollToBottom
      })

    },

    showGoods(id) {
      const routeData = this.$router.resolve({ path: "/ServiceHall/Details", query: { id: id } });
      window.open(routeData.href, '_blank');
    },

    readablizeBytes(value) {
      let s = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
      let e = Math.floor(Math.log(value) / Math.log(1024));
      return (value / Math.pow(1024, Math.floor(e))).toFixed(2) + ' ' + s[e];
    },
    clickContact(contact) {
      this.$store.commit('updateActivatedContact', contact)
      this.sendChannelRead({
        phone: contact.userPhone
      })
    },
  },
  computed: {
    chat() {
      let chat =  this.$store.getters.getShowChat;
      // if (chat.phone !== this.activatedContact)
      //   this.activatedContact = chat.phone
      return chat;
    },
    self() {
      return this.$store.getters.getUser;
    },
    msgList() {
      return this.$store.getters.onGetCurrentChatObjMsg;
    },
    contacts() {
      const _this = this;
      let contacts = _this.$store.getters.contactBook;
      contacts.forEach(item => {
        if (item.time) item.readtime = moment(+item.time).format('YY-MM-DD HH:mm:ss')
        if (item.unread > 99) item.unread = '99'
        if (!item.nickname) item.nickname = item.phone
      })
      return contacts;
    },
    activatedContact() {
      return this.$store.getters.activatedContact;
    },
  },
  watch: {
    activatedContact: {
      handler(newVal, oldVal) {
        const _this = this
        if (newVal && oldVal !== newVal && !newVal.historyLoaded) {
          _this.getHistoryMessage({
            phone: newVal.userPhone,
            isGroup: false,
            callback: _this.scrollToBottom
          });
        }
      }
    }
  }
}
</script>

<style lang="less">
@primary-color: #000000;
@second-color: #9b9b9b;

.el-dialog.yan-chat {
  position: fixed;
  right: 20px;
  bottom: 20px;
  top: 40%;
  margin: 0;
}

.yan-chat .el-dialog__body {
  padding: 0 !important;
  height: calc(100% - 53px);
}
.talk {
  height: 100%;
}

.talk {
  margin: 0 auto;
  display: flex;
}
.contact-container {
  width: 200px;
  height: 100%;
  overflow-y: auto;

  .contact-item-container {
    display: flex;
    align-items: flex-start;
    padding: 6px;
    cursor: pointer;
    background-color: #eae8e7;

    &:hover {
      background-color: #dedcda;
    }
    &.active {
      background-color: #d7d7d7;
    }
    .contact-avatar {
      height: 50px;
    }
    .contact-content-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-left: 8px;

      div + div {
        color: @second-color;
        font-size: 12px;
      }
    }

    .unread {
      background-color: #ff0000;
      border-radius: 50%;
      color: #ffffff;
      width: 20px;
      height: 20px;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
    }
  }

}
.content-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #DCE1E6;
  background: #f5f5f5;
  height: 100%;
}


.emotion {
  width: 22px !important;
  height: 22px !important;
  margin-right: 4px;
  line-height: 40px;
  top: 5px;

}

.face-icon {
  width: 2em;
  height: 2em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.goods {
  background: #ffffff;
  padding: 10px 15px;
  border-radius: 5px;
  max-width: 400px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  .divider {
    margin: 5px 0;
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #dadada;
  }

  .goods-button.el-button {
    max-width: 150px;
    height: 32px;
    padding: 0 20px;
  }
}

.goods-container {
  display: flex;

  .goods-image {
    max-height: 100px;
  }

  .goods-info-container {
    display: flex;
    flex-direction: column;
    margin-left: 5px;

    .goods-desc {
      color: @second-color;
      margin-top: 5px;
    }
  }
}

.message-item-content {
  .goods-container {
    cursor: pointer;
  }
}





.talk-content {
  overflow-y: auto;
  height: calc(100% - 20px);
  margin: 10px 0;

  .message-item-time-container {
    text-align: center;

    .message-item-time {
      font-size: 12px;
      color: @second-color;
    }
  }

  .message-item-container {
    display: flex;
    padding: 0 20px;

    &.right-container {
      flex-direction: row-reverse;

      .message-item-content-container {
        align-items: flex-end;
      }
    }

    .message-item-avatar-container {
      margin-top: 5px;
    }

    .message-item-content-container {
      margin: 0 5px;
      display: flex;
      flex-direction: column;
      align-items: self-start;

      .message-item-name {
        font-size: 12px;
        color: @second-color;

        &.right-name {
          text-align: right;
        }
      }
      .message-status {
        font-size: 12px;
        color: @second-color;
      }

      .message-item-content {
        background: #ffffff;
        padding: 8px 10px;
        margin-top: 5px;
        position: relative;

        .emoji {
          width: 22px;
          height: 22px;
        }

        .message-image {
          height: 100px;
        }
        .message-file-container {
          display: flex;
          align-items: flex-end;

          .filesize {
            font-size: 12px;
            color: @second-color;
            margin-top: 5px;
          }
          .file-download {
            font-size: 12px;
            margin-left: 20px;
          }
        }


        &:after {
          content: "";
          width: 0;
          height: 0;
          font-size: 0;
          overflow: hidden;
          display: block;
          border-width: 5px;
          position: absolute;
          border-color: transparent #ffffff transparent transparent;
          border-style: dashed solid dashed dashed;
          top: 5px;
          left: -10px;
        }

        &.right-content {
          &:after {
            border-color: transparent transparent transparent #ffffff;
            border-style: dashed solid dashed dashed;
            top: 5px;
            left: 100%;
          }
        }

        &:hover {
          background: #ebebeb;

          &:after {
            border-color: transparent #ebebeb transparent transparent;
          }
        }

        &.right-content:hover:after {
          border-color: transparent transparent transparent #ebebeb
        }
      }
    }

  }
}

.talk-message {
  border-top: 1px solid #DCE1E6;
  display: flex;
  padding: 10px 15px;
  align-items: center;

  .talk-message-option-container {
    display: flex;
    align-items: center;

    .upload {
      font-size: 20px;
      margin-left: 3px;
    }
  }
}

.talk-message-face {
  position: relative;
  cursor: pointer;
}

.talk-message-content {
  flex: 1;
  margin-left: 10px;

}

.talk-message-send > button {
  color: #606266;
  padding: 10px 20px;
  margin-left: 10px;

  &:hover, &:active {
    border-color: transparent !important;
  }
}
</style>
