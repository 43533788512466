export default {
    // 服务商 页面
    Service: [
        {
            path: "/Service/Home", //服务商 主页
            name: "ServiceHome",
            component: () => import("@/views/Service/Home.vue"),
            // meta: {
            //     requireAuth: true // 需要验证登录状态
            // },
        }
    ]
}