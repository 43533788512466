import WebIM from '@/utils/WebIM/WebIM';
import store from '@/store'
import _ from "lodash";
import axios from "@/utils/request"

const Chat = {
    state: {
        msgList: {
            contact: {},
        },
        currentMsgs: [],
        // 对话列表，值为对象，key为用户id，值为最新一条消息，新到消息
        contactBook: [],
        activatedContact: '',
        chatTips: {
            show: false,
            phone: ''
        },
        chat: {
            show: false,
            phone: '',
            name: '',
            goods: {
                id: '',
                name: '',
                desc: '',
                img: '',
            }
        },
    },
    mutations: {
        setShowChat(state, data) {
            if (!data.show) {
                state.chat = {
                    show: false,
                    phone: '',
                    name: '',
                    goods: {
                        id: '',
                        name: '',
                        desc: '',
                        img: '',
                    }
                }
                state.activatedContact = ''
                state.currentMsgs = []
            }
            else state.chat = data;
        },
        addToMsgList(state, payload)  {
            state.msgList['contact'][payload.phone] = []
        },

        updateMsgList(state, payload) {
            // console.log('updateMsgList', state, payload);
            const { chatType, chatId, msg, bySelf, type, id } = payload;
            // payload的消息为漫游历史消息的话，进入判断筛选出已存在msgList当中的消息，此类消息不再添加进msgList。
            if (payload.isHistory) {
                // 拿到该key已经存在的消息。
                let nowKeyMsg = state.msgList[chatType][chatId];
                // 开始筛选，如果payload.mid 不等于item.id则说明msgList中没有存储。
                let newHistoryMsg = nowKeyMsg && nowKeyMsg.filter(item => {
                    return item.mid != payload.mid;
                });
                state.msgList[chatType][chatId] = newHistoryMsg;
            }

            if (!state.msgList[chatType][chatId]) {
                state.msgList[chatType][chatId] = [];
            }
            state.msgList[chatType][chatId].push({
                msg,
                bySelf,
                type: type || '',
                mid: id,
                status,
                ...payload
            });
            state.msgList[chatType][chatId] = state.msgList[chatType][chatId].sort((a, b) => {
                return a.time - b.time;
            });

            state.msgList = Object.assign({}, state.msgList);
        },
        updateCurrentMsgList(state, messages) {
            // console.log(messages, 'updateCurrentMsgList');
            state.currentMsgs = messages;
        },
        // 消息送达服务器，更新消息 id
        updateMessageMid(state, message) {
            const { id, mid, to} = message
            let name = 'contact'
            if (state.msgList[name][to]) {
                state.msgList[name][to].forEach((msg) => {
                    if (msg.mid === id) {
                        msg.mid = mid;
                    }
                });
            }
        },
        // 消息已读，更新指定消息 id 的状态
        updateMessageStatus(state, message) {
            const { mid, from, status } = message;
            let name = 'contact'
            if (state.msgList[name][from]) {
                let msg = state.msgList[name][from].find(msg => msg.mid === mid)
                if (msg) msg.status = status
            }
        },
        // 会话已读，更新指定对话的状态
        updateMessageChannelStatus(state, message) {
            const { from, status } = message;
            let name = 'contact'
            if (state.msgList[name][from]) {
                let msgs = state.msgList[name][from].filter(msg => msg.status !== status)
                msgs.forEach(msg => msg.status = status)
            }
        },

        initChatState(state) {
            state.userList = {
                contactUserList: [],
                groupUserList: [],
                chatroomUserList: []
            };

            state.msgList = {
                contact: {},
                group: {},
                chatroom: {},
            };

            state.currentMsgs = [];
        },

        // 传递数据给 call 组件，是否收到通话邀请
        noticeCall(state, payload) {
            // console.log('store noticeCall msg', payload);
            state.noticeCallMsg = payload;
        },
        initContactBook(state, payload) {
            payload.forEach(item => {
                item.phone = item.userPhone
                state.contactBook.push({
                    historyLoaded: false,
                    unread: 0,
                    ...item
                })
            })
        },
        addContactBook(state, payload) {
            state.contactBook.push({
                unread: 0,
                historyLoaded: false,
                ...payload
            })
        },
        updateContactBook(state, payload) {
            let item = _.find(state.contactBook, {phone: payload.id})
            if (item) {
                item.time = payload.time
                item.msg = payload.msg
            }
            _.orderBy(state, ['time'], ['desc'])
        },
        updateActivatedContact(state, payload) {
            if (state.contactBook && state.contactBook.length) {
                if (payload && payload.phone) {
                    state.activatedContact = state.contactBook.find(item => payload.phone === item.phone)
                }
                else
                    state.activatedContact = state.contactBook[0]
            }

            // 将对话消息置为已读
            if (payload && payload.phone && state.msgList['contact'][payload.phone]) {
                state.msgList['contact'][payload.phone].forEach(item => {
                    if (item.status !== 'read') item.status = 'read'
                })
                state.currentMsgs = state.msgList['contact'][payload.phone]
            }
            else
                state.currentMsgs = []
        },
        resetUnread(state) {
            // 清空未读标记
            state.activatedContact.unread = 0
        },
        loadedHistory(state, payload) {
            // 更新对话状态，已加载历史消息标志置为 true
            let contact = state.contactBook.find(item => item.phone === payload.phone)
            if (contact) contact.historyLoaded = true;

            if (state.msgList['contact'][payload.phone])
                state.currentMsgs = state.msgList['contact'][payload.phone]
            else
                state.currentMsgs = []
        },
        updateUnreadNumber(state, payload) {
            let contact = state.contactBook.find(item => item.phone === payload.from)
            if (contact) {
                contact.time = payload.time;
                switch (payload.type) {
                    case 'txt':
                        contact.msg = payload.msg
                        break;
                    case 'img':
                        contact.msg = '图片'
                        break;
                    case 'file':
                        contact.msg = '文件'
                        break;
                    case 'custom':
                        contact.msg = '链接'
                        break;
                    default:
                        break;
                }
            }
            contact.unread++;

            // 获取除当前对话的其余对话，按照当前对话置顶，依次是最新消息对话
            let arr = state.activatedContact
                ? state.contactBook.filter(item => item.phone !== state.activatedContact.phone)
                : state.contactBook
            arr.sort((a, b) => {
                if (a.time && b.time) return b.time - a.time
                if (a.time && !b.time) return -1
                if (!a.time && b.time) return 1
            })
            state.contactBook = state.activatedContact ? [state.activatedContact, ...arr] : arr;

            // 显示右下角聊天图标
            state.chatTips = {
                show: true,
                phone: payload.from
            }
        },
    },
    actions: {
        getUserInfo(context, payload) {
            return axios.get(`app-api/hiring/user/phones`, {params: {phones: payload.join(',')}});
        },
        showChat({ commit, state, dispatch }, data) {
            commit('setShowChat', data);
            // 手机号存在，点击咨询、对话按钮，
            // 新联系对象要加好友，初始化联系信息，并设置为当前联系对象
            // 否则取通讯录内信息，设置为当前联系对象
            if (data.phone) {
                // 如果联系人不在好友列表，调用环信新增好友
                let contact = state.contactBook.find(item => item.phone === data.phone)
                if (!contact) {
                    dispatch('onAddContact', { to: data.phone })
                    dispatch('getUserInfo', [data.phone])
                        .then((res) => {
                            if (res.status === 200
                                && res.data.code === 0
                                && res.data.data.length
                            ) {
                                contact = res.data.data[0]
                                commit('addContactBook', contact)
                                commit('updateActivatedContact', contact)
                            }
                        })
                        .catch((err) => {
                            return Promise.reject(err);
                        });
                } else {
                    commit('updateActivatedContact', contact)
                    dispatch('sendChannelRead', {
                        phone: data.phone
                    })
                }

            } else {
                commit('updateActivatedContact')
            }

        },
        hideChat({ commit }) {
            commit('setShowChat', { show: false });
        },
        onLogin: function(context, payload){
            let options = {
                user: payload.username,
                pwd: payload.password,
                appKey: WebIM.config.appkey
            };
            return WebIM.conn.open(options);
        },
        onLogout: function(){
            // context.commit('setUserName', '');
            // localStorage.setItem('userInfo', '');
            WebIM.conn.close();
        },
        onRegister: function(context, payload){
            // context.commit('setUserName', payload.username)
            var options = {
                username: payload.username,
                password: payload.password,
                appKey: WebIM.config.appkey,
                success: () => {
                    // Message.success('注册成功');
                    // context.commit('setRegisterFlag', false);
                },
                error: (err) => {
                    if(JSON.parse(err.data).error == 'duplicate_unique_property_exists'){
                    //     Message.error('用户已存在！');
                    }
                    else if(JSON.parse(err.data).error == 'illegal_argument'){
                        // if(JSON.parse(err.data).error_description === 'USERNAME_TOO_LONG'){
                        //     return message.error('用户名超过64个字节！');
                        // }
                        // Message.error('用户名不合法！');
                    }
                    else if(JSON.parse(err.data).error == 'unauthorized'){
                        // Message.error('注册失败，无权限！');
                    }
                    else if(JSON.parse(err.data).error == 'resource_limited'){
                        // Message.error('您的App用户注册数量已达上限,请升级至企业版！');
                    }
                }
            };
            WebIM.conn.registerUser(options);
        },
        onGetContacts(context) {
            return WebIM.conn.getContacts().then((res) => {
                context.dispatch('getUserInfo', res.data)
                    .then((res) => {
                        if (res.data.code === 0) {
                            context.commit('initContactBook', res.data.data)
                        }
                    })
                    .catch((err) => {
                        return Promise.reject(err);
                    });
            })
        },
        onAddContact(context, payload) {
            // console.log('context, payload ', context, payload)
            WebIM.conn.addContact(payload.to, "加个好友呗!");
        },
        onGetCurrentChatObjMsg: function (context, payload) {
            // console.log(context, payload, 'onGetCurrentChatObjMsg');
            const { id, type } = payload;
            context.commit('updateCurrentMsgList', context.state.msgList[type][id]);
        },
        onSendText: function (context, payload) {
            // console.log('onSendText payload', payload)
            const { chatType, chatId, message, callback } = payload;
            const id = WebIM.conn.getUniqueId();
            const type = chatType === 'contact' ? 'singleChat' : 'groupChat';
            const time = +new Date();
            const msgObj = new WebIM.message('txt', id);
            msgObj.set({
                msg: message,
                to: chatId,
                chatType: type,
                roomType: false,
                success: function () {
                    context.commit('updateMsgList', {
                        chatType,
                        chatId: chatId,
                        msg: message,
                        bySelf: true,
                        time: time,
                        mid: id,
                        type: 'txt',
                        status: 'sending'
                    });
                    callback();
                },
                fail: function (e) {
                    if (payload.error) payload.error(e)
                    // console.log('Send private text error', e);
                }
            });
            if (chatType === 'group' || chatType === 'chatroom') {
                msgObj.setGroup('groupchat');
            }
            WebIM.conn.send(msgObj.body);
        },
        sendImgMessage: function (context, payload) {
            const { chatType, chatId, file, callback, error } = payload;
            const id = WebIM.conn.getUniqueId();
            const type = chatType === 'contact' ? 'singleChat' : 'groupChat';
            const msgObj = new WebIM.message('img', id);
            msgObj.set({
                file: file,
                to: chatId,
                chatType: type,
                roomType: false,
                onFileUploadError: function (e) {
                    // console.log('图片上传失败', error);
                    if (error) error(e)
                },
                onFileUploadComplete: function (data) {
                    // console.log('图片上传完成', data);
                    let url = data.uri + '/' + data.entities[0].uuid;
                    context.commit('updateMsgList', {
                        msg: url,
                        chatType,
                        chatId: chatId,
                        bySelf: true,
                        type: 'img',
                        time: data.timestamp,
                        mid: id,
                        status: 'sending'
                    });
                },
                success: function () {
                    // console.log('图片发送成功');
                    callback();
                }
            });
            if (chatType === 'group' || chatType === 'chatroom') {
                msgObj.setGroup('groupchat');
            }
            WebIM.conn.send(msgObj.body);
        },
        sendFileMessage: function (context, payload) {
            const { chatType, chatId, roomType, file, callback, error } = payload;
            const id = WebIM.conn.getUniqueId();
            const type = chatType === 'contact' ? 'singleChat' : 'groupChat';
            const msgObj = new WebIM.message('file', id);
            msgObj.set({
                file: file,
                ext: {
                    file_length: file.data.size
                },
                to: chatId,
                chatType: type,
                roomType: roomType,
                onFileUploadError: function (e) {
                    // console.log('文件上传失败', error);
                    if (error) error(e)
                },
                onFileUploadComplete: function (data) {
                    let url = data.uri + '/' + data.entities[0].uuid;
                    context.commit('updateMsgList', {
                        msg: url,
                        chatType,
                        chatId: chatId,
                        bySelf: true,
                        type: 'file',
                        filename: file.data.name,
                        file_length: file.data.size,
                        time: data.timestamp,
                        mid: id,
                        status: 'sending'
                    });
                },
                success: function () {
                    // console.log('文件发送成功');
                    callback();
                }
            });
            if (chatType === 'group' || chatType === 'chatroom') {
                msgObj.setGroup('groupchat');
            }
            WebIM.conn.send(msgObj.body);
        },
        sendCustomMessage: function (context, payload) {
            const { chatType, chatId, message, callback, error } = payload;
            const id = WebIM.conn.getUniqueId();
            const type = chatType === 'contact' ? 'singleChat' : 'groupChat';
            const time = +new Date();
            const msgObj = new WebIM.message('custom', id);
            msgObj.set({
                customExts: message,
                to: chatId,
                chatType: type,
                roomType: false,
                success: function () {
                    context.commit('updateMsgList', {
                        chatType,
                        chatId: chatId,
                        msg: message,
                        bySelf: true,
                        time: time,
                        mid: id,
                        type: 'goods',
                        status: 'sending'
                    });
                    callback();
                },
                fail: function (e) {
                    // console.log('Send private text error', e);
                    if (error) error(e)
                }
            });
            if (chatType === 'group' || chatType === 'chatroom') {
                msgObj.setGroup('groupchat');
            }
            WebIM.conn.send(msgObj.body);
        },

        getHistoryMessage: function (context, payload) {
            const options = {
                targetId: payload.phone,
                // 每页期望获取的消息条数。取值范围为 [1,50]，默认值为 20。
                pageSize: 20,
                // 查询的起始消息 ID。若该参数设置为 `-1`、`null` 或空字符串，从最新消息开始。
                cursor: -1,
                chatType: payload.isGroup ? 'groupChat' : 'singleChat',
                // 消息搜索方向：（默认）`up`：按服务器收到消息的时间的逆序获取；`down`：按服务器收到消息的时间的正序获取。
                searchDirection: "up",
            };
            WebIM.conn.getHistoryMessages(options)
                .then(res => {
                    try {
                        // console.log(res, 'msgs=getHistoryMessage');
                        payload.success && payload.success(res);
                        let messages = res.messages;
                        if (messages.length) {
                            messages.forEach((item) => {
                                let time = Number(item.time);
                                let msg = {};
                                const bySelf = item.from == store.getters.getUser.userPhone;
                                // 文本
                                if (item.type === 'txt') {
                                    msg = {
                                        chatType: payload.isGroup ? 'group' : 'contact',
                                        chatId: bySelf ? item.to : item.from,
                                        msg: item.msg,
                                        bySelf: bySelf,
                                        time: time,
                                        mid: item.id,
                                        status: 'read',
                                        type: item.type
                                    };
                                    if (payload.isGroup) {
                                        msg.chatId = item.to;
                                    } else {
                                        msg.chatId = bySelf ? item.to : item.from;
                                    }
                                } else if (item.type === 'img') { // 为图片的情况
                                    msg = {
                                        msg: item.url,
                                        chatType: payload.isGroup ? 'group' : 'contact',
                                        chatId: bySelf ? item.to : item.from,
                                        bySelf: bySelf,
                                        type: item.type,
                                        time: time,
                                        mid: item.id,
                                        status: 'read'
                                    };
                                    if (payload.isGroup) {
                                        msg.chatId = item.to;
                                    } else {
                                        msg.chatId = bySelf ? item.to : item.from;
                                    }
                                } else if (item.filename === 'audio') {
                                    msg = {
                                        msg: item.url,
                                        chatType: payload.isGroup ? 'group' : 'contact',
                                        chatId: bySelf ? item.to : item.from,
                                        bySelf: bySelf,
                                        type: 'audio',
                                        time: time,
                                        mid: item.id,
                                        status: 'read'
                                    };
                                    if (payload.isGroup) {
                                        msg.chatId = item.to;
                                    } else {
                                        msg.chatId = bySelf ? item.to : item.from;
                                    }
                                } else if (item.filename === 'video') {
                                    msg = {
                                        msg: item.url,
                                        chatType: payload.isGroup ? 'group' : 'contact',
                                        chatId: bySelf ? item.to : item.from,
                                        bySelf: bySelf,
                                        type: 'video'
                                    };
                                    if (payload.isGroup) {
                                        msg.chatId = item.to;
                                    } else {
                                        msg.chatId = bySelf ? item.to : item.from;
                                    }
                                } else if (item.type === 'file') {
                                    msg = {
                                        msg: item.url,
                                        chatType: payload.isGroup ? 'group' : 'contact',
                                        chatId: bySelf ? item.to : item.from,
                                        bySelf: bySelf,
                                        type: item.type,
                                        filename: item.filename,
                                        file_length: item.file_length,
                                        time: time,
                                        mid: item.id,
                                        status: 'read'
                                    };
                                    if (payload.isGroup) {
                                        msg.chatId = item.to;
                                    } else {
                                        msg.chatId = bySelf ? item.to : item.from;
                                    }
                                } else if (item.type === 'custom') {
                                    msg = {
                                        msg: item.customExts,
                                        chatType: payload.isGroup ? 'group' : 'contact',
                                        chatId: bySelf ? item.to : item.from,
                                        bySelf: bySelf,
                                        type: item.type,
                                        time: time,
                                        mid: item.id,
                                        status: 'read'
                                    };
                                    if (payload.isGroup) {
                                        msg.chatId = item.to;
                                    } else {
                                        msg.chatId = bySelf ? item.to : item.from;
                                    }
                                }
                                msg.isHistory = true;
                                context.commit('updateMsgList', msg);

                            });
                        }
                        else {
                            context.commit('addToMsgList', {
                                phone: payload.phone
                            });
                        }

                        context.commit('loadedHistory', payload);
                        if (payload.callback) payload.callback()
                    } catch (e) {
                        // console.log('error', e);
                    }
                })
                .catch((e) => {
                    if (payload.error) payload.error(e)
                    // 获取失败。
                    // console.log('error', e);
                });
        },

        initChatState: function (context, payload) {
            // console.log('initChatState payload %o', payload)
            context.commit('initChatState', payload);
        },
        deleteMessage: function (context, payload) {
            const { chatType, chatId, messageIds, callback, error } = payload;
            WebIM.conn.removeHistoryMessages({
                targetId: chatId,
                chatType: chatType === 'contact' ? 'singleChat' : 'groupChat',
                messageIds: messageIds
            })
                .then(res => {
                    // console.log('删除成功', res)
                    if (callback) callback(res)
                })
                .catch(e => {
                    if (error) error(e)
                    // console.log('删除失败', e)
                })

        },
        deleteConversation: function (context, payload) {
            const { chatType, chatId, callback, error } = payload;
            WebIM.conn.deleteConversation({
                channel: chatId,
                chatType: chatType === 'contact' ? 'singleChat' : 'groupChat',
                // 删除会话时是否同时删除服务端漫游消息。
                deleteRoam: true,
            })
                .then(res => {
                    // console.log('删除成功', res)
                    if (callback) callback(res)
                })
                .catch(e => {
                    if (error) error(e)
                    // console.log('删除失败', e)
                })

        },
        // 发送会话已读
        sendChannelRead(context, payload) {
            if (!payload.phone || !context.state.activatedContact.unread) return

            let option = {
                chatType: "singleChat", // 会话类型，设置为单聊。
                type: "channel", // 消息类型。
                to: payload.phone, // 接收消息对象的用户 ID。
            };
            let msg = WebIM.message.create(option);
            WebIM.conn.send(msg)
                .then(() => context.commit('resetUnread'))
                .catch(e => console.log('sendChannelRead error', e));
        },
        // 发送消息已读
        sendMessageRead(context, payload) {
            // 当用户打开聊天窗，并且当前对话方是消息发送方，发送消息已读
            if (context.state.chat.show
                && payload.from === context.state.activatedContact.phone) {
                let option = {
                    type: "read", // 消息是否已读。
                    chatType: "singleChat", // 会话类型，这里为单聊。
                    to: payload.from, // 消息接收方的用户 ID。
                    id: payload.mid, // 需要发送已读回执的消息 ID。
                };
                let msg = WebIM.message.create(option);
                WebIM.conn.send(msg);
            }

        },
    },
    getters: {
        onGetCurrentChatObjMsg(state) {
            // console.log(state, 'state, payload====state, payload');
            return state.currentMsgs;
        },
        fetchHistoryMessages(state) {
            return state.currentMsgs;
        },
        contactBook(state) {
            return state.contactBook;
        },
        activatedContact(state) {
            return state.activatedContact;
        },
        getChatTips(state) {
            return state.chatTips
        },
        getShowChat(state) {
            return state.chat
        },
    }

};
export default Chat;
