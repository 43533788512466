// 订单 详情 页面路由
// 导出
export default {
  Order: [
    {
      path: "/Order/Buy",
      name: "Buy",
      component: () => import("@/views/Order/Buy.vue"),
      meta: {
        requireAuth: true // 需要验证登录状态
      },
    },
    {
      path: "/Order/Payment",
      name: "Payment",
      component: () => import("@/views/Order/Payment.vue"),
      meta: {
        requireAuth: true // 需要验证登录状态
      },
    },
    {
      path: "/Order/Provider/Detail",
      name: "ServiceDetail",
      component: () => import("@/views/Order/OrderDetailProvider.vue"), //服务订单 详情
      meta: {
        requireAuth: true // 需要验证登录状态
      },
    },
    {
      path: "/Order/Detail",
      name: "Detail",
      component: () => import("@/views/Order/OrderDetailUser.vue"),  //我的订单 详情
      meta: {
        requireAuth: true // 需要验证登录状态
      },
    },
    {
      path: "/Order/Appeal",
      name: "Appeal",
      component: () => import("@/views/Order/Appeal.vue"),  //订单 申诉
      meta: {
        requireAuth: true // 需要验证登录状态
      },
    }
  ],
};
