/*公共方法*/
/* eslint-disable*/
export default {
    cityFilter(data, pid) {
        let tree = [];
        let temp;
        for (let i = 0; i < data.length; i++) {
            if (data[i].id === pid) {
                let obj = data[i];
                temp = this.cityFilter(data, data[i].id);
                obj.total = temp.length;
                obj.label = data[i].name;
                obj.value = data[i].id;
                if (temp.length > 0) {
                    obj.children = temp;
                }
                tree.push(obj);
            }
        }
        return tree;
    },
    test:'11111'
}
