import websdk from "easemob-websdk";
import _ from 'lodash';
import store from '@/store/index'
import config from './WebIMConfig';

// 初始化IM SDK
let WebIM = {};
WebIM = window.WebIM = websdk;
WebIM.config = config;
WebIM.logger.disableAll(true)
WebIM.conn = new WebIM.connection({
    appKey: config.appkey,
    https: config.https,
});

// 注册监听回调
WebIM.conn.listen({
    // 连接成功回调
    // onOpened: function (message) {
    //     console.log('连接成功回调 onOpened message: %o', message)
    // },
    // 连接关闭回调
    // onClosed: function (message) {
    //     console.log('连接关闭回调 onClosed message %o', message)
    // },
    // 收到文本消息
    onTextMessage: function (message) {
        // console.log('收到文本消息 onTextMessage message %o', message);
        const { from, time } = message;
        let payload = {
            chatType: 'contact',
            chatId: from,
            msg: message.data,
            bySelf: false,
            from: from,
            mid: message.id,
            time: time,
            status: 'unread',
            type: 'txt'
        }
        store.commit('updateMsgList', payload);
        store.commit('updateUnreadNumber', payload);
        store.dispatch('sendMessageRead', payload);
    },
    // 收到自定义消息
    onCustomMessage: function (message) {
        // console.log('收到自定义消息 onCustomMessage message %o', message);
        const { from, time } = message;
        let payload = {
            chatType: 'contact',
            chatId: from,
            msg: message.customExts,
            bySelf: false,
            from: message.from,
            mid: message.id,
            time: time,
            status: 'unread',
            type: 'coustom'
        }
        store.commit('updateUnreadNumber', payload)
        store.dispatch('sendMessageRead', payload);
    },
    // 收到图片消息
    onPictureMessage: function (message) {
        // console.log('收到图片消息 onPictureMessage message %o', message);
        const { from, time } = message;
        let payload = {
            chatType: 'contact',
            chatId: from,
            msg: message.url,
            bySelf: false,
            type: 'img',
            from: message.from,
            time: time
        }
        store.commit('updateMsgList', payload);
        store.commit('updateUnreadNumber', payload)
        store.dispatch('sendMessageRead', payload);
    },
    // 收到文件消息
    onFileMessage: function (message) {
        // console.log('收到文件消息 onFileMessage message %o', message)
        const { from, time } = message;
        let payload = {
            chatType: 'contact',
            chatId: from,
            msg: message.url,
            bySelf: false,
            type: 'file',
            filename: message.filename,
            file_length: message.file_length,
            from: from,
            time: time
        }
        store.commit('updateMsgList', payload);
        store.commit('updateUnreadNumber', payload)
        store.dispatch('sendMessageRead', payload);
    },

    // 处理群组邀请
    // onInviteMessage: function (message) {
    //     console.log('onInviteMessage', message);
    // },
    // 本机网络连接成功
    // onOnline: function () {
    //     console.log('onOnline 网络已连接');
    // },
    // 本机网络掉线
    // onOffline: function () {
    //     console.log('onOffline 网络已断开');
    // },
    // 失败回调
    onError: function (message) {
        // console.log('失败回调 onError', message);
        if (message.type == 0) {
            return this.$message.error('请输入账号密码');
        } else if (message.type == 28) {
            return console.log('未登陆');
        } else if (_.get(message, 'data.type') === 17 && JSON.parse(message.data.data).error_description == 'user not found') {
            return this.$message.error('用户名不存在！');
        } else if (_.get(message, 'data.type') === 17 && JSON.parse(message.data.data).error_description == 'invalid password') {
            return this.$message.error('密码无效！');
        } else if (_.get(message, 'data.type') === 17 && JSON.parse(message.data.data).error_description == 'user not activated') {
            return this.$message.error('用户已被封禁！');
        } else if (message.type == '504') {
            return this.$message.error('消息撤回失败');
        }
        // 报错返回到登录页面
        // this.$router.push({ path: '/login' });
    },
    onRecallMessage: message => {
        // console.log('撤回消息', message);
        message.status = 'recall';
        message.msg = '对方撤回了一条消息';
        store.commit('updateMessageStatus', message);
    },
    onBlacklistUpdate: function (list) { // 黑名单变动
        // 查询黑名单，将好友拉黑，将好友从黑名单移除都会回调这个函数，list则是黑名单现有的所有好友信息
        // 更新好友黑名单
        // console.log('黑名单变动', list);
        let blackList = list;
        store.commit('updateBlackList', blackList);
    },
    // 收到消息送达服务器回执
    onReceivedMessage: function (message) {
        // console.log('收到消息送达服务器回执 onReceivedMessage', message);
        store.commit('updateMessageMid', message);
        message.status = 'sent';
        message.from = message.to;
        store.commit('updateMessageStatus', message);
    },
    // 收到会话已读回执
    onChannelMessage: function (message) {
        // console.log('收到会话已读回执 onChannelMessage', message);
        message.status = 'read';
        store.commit('updateMessageChannelStatus', message);
    },
    // 收到消息送达客户端回执
    // onDeliveredMessage: function (message) {
    //     console.log('收到消息送达客户端回执 onDeliveredMessage', message);
    //     // store.commit('updateMessageStatus', message)
    // },
    // 收到消息已读回执
    onReadMessage: function (message) {
        // console.log("收到消息已读回执 onReadMessage", message);
        message.status = 'read';
        store.commit('updateMessageStatus', message);
    },
    // 创建群组成功回执（需调用createGroupNew）
    onCreateGroup: function (message) {
        // console.log('onCreateGroup', message);
        store.dispatch('onGetGroupUserList', message);
    },
    // 如果用户在A群组被禁言，在A群发消息会走这个回调并且消息不会传递给群其它成员
    // onMutedMessage: function (message) {
    //     console.log('onMutedMessage', message);
    // },
    // 发布者发布新的状态时，订阅者触发该回调
    onPresenceStatusChange: function (message) {
        // console.log('onPresenceStatusChange', message);
        const { userId } = JSON.parse(window.localStorage.getItem('userInfo'));
        // const { data: { values } } = message
        if (userId != message[0].userId) {
            store.dispatch('onGetContactUserList');
        } else {
            store.commit('updateUserPresenceStatus', message[0].ext);
        }
    },
});
WebIM.conn.addEventHandler("contactEvent", {
    // 当前用户收到好友请求。用户 B 向用户 A 发送好友请求，用户 A 收到该事件。
    onContactInvited: function (msg) {
        WebIM.conn.acceptContactInvite(msg.from)
        // 添加到通讯录
        store.commit('addContactBook', {
            chatType: 'singleChat',
            chatId: msg.from,
            time: +new Date(),
            msg: ''
        });
    },
    // 当前用户被其他用户从联系人列表上移除。用户 B 将用户 A 从联系人列表上删除，用户 A 收到该事件。
    // onContactDeleted: function (msg) {
    //     console.log(msg)
    // },
    // 当前用户新增了联系人。用户 B 向用户 A 发送好友请求，用户 A 同意该请求，用户 A 收到该事件，而用户 B 收到 `onContactAgreed` 事件。
    // onContactAdded: function (msg) {
    //     console.log(msg)
    // },
    // 当前用户发送的好友请求被拒绝。用户 A 向用户 B 发送好友请求，用户 B 收到好友请求后，拒绝加好友，则用户 A 收到该事件。
    // onContactRefuse: function (msg) {
    //     console.log(msg)
    // },
    // 当前用户发送的好友请求经过了对方同意。用户 A 向用户 B 发送好友请求，用户 B 收到好友请求后，同意加好友，则用户 A 收到该事件。
    // onContactAgreed: function (msg) {
    //     console.log(msg)
    // },
});

export default WebIM;