/*
 * 入口文件
 * @Author: hai-27
 * @Date: 2020-02-07 16:23:00
 * @LastEditors: hai-27
 * @LastEditTime: 2020-03-04 23:38:41
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import './assets/css/theme/index.css';
import "./assets/css/common.css";
// 全局函数及变量
import Global from './Global';
// 图片上传组件
import ImageUpload from "@/components/ImageUpload"
// 弹框 组件
import modal from '@/assets/js/modal'
// 接口地址
import Http from "@/utils"
// 混入
import Mixin from "@/utils/mixin"
// 公共滚动
import scroll from 'vue-seamless-scroll'
// 聊天
import chat from './components/Chat'
// 倒计时
import VueCountdown from '@chenfengyuan/vue-countdown';

Vue.use(ElementUI);

Vue.use(Global);

import request from "@/utils/request" // 实现 form generator 使用自己定义的 axios request 对象
// console.log(request)
Vue.prototype.$axios = request

// 全局拦截器,在进入需要用户权限的页面前校验是否已经登录
router.beforeEach((to, from, next) => {
  const loginUser = store.state.user.user;
  if (!from.name) {
    next()
  } else {
    // 判断路由是否设置相应校验用户权限
    if (to.meta.requireAuth && !loginUser) {
      store.dispatch("setShowLogin", true);
      next({ name: 'login' });
    } else {
      next();
    }
  }
});


//全局组件
// import MyMenu from './components/MyMenu';
// Vue.component(MyMenu.name, MyMenu);

// import MyList from './components/MyList';
// Vue.component(MyList.name, MyList);

// import MyLogin from './components/MyLogin';
// Vue.component(MyLogin.name, MyLogin);
//
// import MyRegister from './components/MyRegister';
// Vue.component(MyRegister.name, MyRegister);

Vue.component('ImageUpload', ImageUpload)

Vue.prototype.$modal = modal

Vue.prototype.$Http = Http

Vue.mixin(Mixin)

Vue.use(scroll)

Vue.use(chat)

Vue.component(VueCountdown.name, VueCountdown);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
