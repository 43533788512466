<template>
  <div class="head">
    <div class="ad" @click="clickAd" :class="getPageConfig.ad.top ? 'active': ''">
      《积极维护防汛救灾网络秩序倡议书》
      <i class="el-icon-circle-close close" @click.stop="closeTopAd"></i>
    </div>
    <div class="Heard">
      <div class="Info">
        <el-popover v-if="!HeardsShow"
            v-model="showPopover"
            placement="bottom"
            trigger="click">
          <el-cascader-panel class="location" placeholder="请选择地区"
                             :options="cityList"
                             :props="{ expandTrigger: 'hover' }"
                             v-model="city.value"
                             @change="changeLocation"
                             ref="location"
                             filterable clearable>
          </el-cascader-panel>
          <div class="location-container" slot="reference">
            <i class="el-icon-location-outline"></i>
            <span>{{ city.label }}</span>
            <el-link :underline="false">[切换]</el-link>
          </div>
        </el-popover>
        <img v-if="HeardsShow" :src="require('@/assets/logo.png')" alt class="img" @click="clickLogo" />
        <!-- 搜索框 -->
        <Search v-show="!isLoginPage" :Left="40" v-if="HeardsShow" />
        <!-- <div style="width: 420px; height: 1px" v-else></div> -->
        
        <div v-show="!isLoginPage" class="Heard-flex">
          <NavMenu style="margin-right: 30px" ref="NavMenu" :Left="20" />
          <!-- 用户中心 -->
          <!-- 判断是否登录 -->
          <User ref="User" v-show="UserInfo.id" />
          <div class="IsLogin" v-show="!UserInfo.id">
            <!-- 栏目 -->
            
            <div @click="login">登录</div>
            <span class="sep">|</span>
            <div @click="register">注册</div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import datas from "./index";
import Search from "@/components/Search/index.vue";
import NavMenu from "@/components/Heads/NavMenu.vue";
import User from "@/components/Heads/User.vue";
import { mapGetters, mapActions } from "vuex";
import commonJs from "@/assets/js/common";
import _ from "lodash";

export default {
  data() {
    return {
      datas,
      show: true,
      UserInfo: "",
      ad: '',
      showPopover: false,
      city: {
        label: '安徽-亳州',
        value: [3, 51]
      },
      curMenu: ''
    };
  },
  components: {
    // Image,
    Search,
    NavMenu,
    User,
  },
  props: {
    HeardsShow: {
      type: Boolean,
      default: true,
    },
  },
  created() {
  },
  
  mounted() {
    let Menu = this.datas.Menu;
    if (this.$store.getters.getUser) {
      let Dropdown = this.datas.Dropdown;
      if (this.$store.getters.getUser.serviceStatus === 0) {
        let arr = [];
        for (let x = 0; x < Dropdown.length; x++) {
          if (x < 2) {
            arr.push(Dropdown[x]);
          }
        }
        this.$refs.User.Dropdown = arr;
      } else {
        this.$refs.User.Dropdown = Dropdown;
        Menu[2].routerName = "ProviderJoined";
      }
      this.$refs.User.circleUrl = this.$store.getters.getUser.avatarImg;
      this.UserInfo = this.$store.getters.getUser;
    }
    this.$refs.NavMenu.Menu = Menu;
  },
  computed: {
    ...mapGetters(["getUser", "getPageConfig"]),
    isLoginPage() {
      return this.$route.name === 'login'
    },
    cityList() {
      const data = this.$store.getters.getCity;
      const pid = 1;
      return commonJs.cityFilter(data, pid);
    }
  },
  watch: {
    // 获取vuex的登录状态
    getUser: function (val) {
      if (val.id) {
        let Dropdown = this.datas.Dropdown;
        let Menu = this.datas.Menu;
        if (this.$store.getters.getUser.serviceStatus === 0) {
          let arr = [];
          for (let x = 0; x < Dropdown.length; x++) {
            if (x < 2) {
              arr.push(Dropdown[x]);
            }
          }
          this.$refs.User.Dropdown = arr;
        } else {
          this.$refs.User.Dropdown = Dropdown;
          Menu[2].routerName = "ProviderJoined";
        }
        this.$refs.NavMenu.Menu = Menu;
        this.$refs.User.circleUrl = this.$store.getters.getUser.avatarImg;
        this.UserInfo = this.$store.getters.getUser;
      } else {
        this.UserInfo = "";
      }
    },
  },
  
  methods: {
    ...mapActions(["setShowLogin", "setShowRegister", 'setAd']),
    login() {
      this.setShowLogin(true);
      this.setShowRegister(false);
      this.$router.push({ name: 'login' })
    },
    register() {
      this.setShowLogin(false);
      this.setShowRegister(true);
      this.$router.push({ name: 'login' })
    },
    clickLogo() {
      this.$router.push({ name: 'Home' })
    },
    clickAd() {
      this.$router.push({
        name: 'post',
        query: {
          id: 12
        }
      })
    },
    closeTopAd() {
      this.setAd({ top: false })
    },
    changeLocation() {
      let nodes = this.$refs.location.getCheckedNodes()
      if (nodes && nodes.length) {
        let pathNodes = nodes[0].pathNodes;
        this.city = {
          label: _.map(pathNodes, 'label').join('-'),
          value: _.map(pathNodes, 'value'),
        }
      }
      this.showPopover = false
    },
    goTo(link) {
      this.$router.push(link.router)
      this.curMenu = link.name
    }
  },
};
</script>
<style lang="less">
.location.el-cascader-panel.is-bordered {
  border: none;
}
</style>
<style lang="less" scoped>
.head {
  position: sticky;
  top: 0;
  z-index: 99;
  background: #ffffff;
}

.ad {
  position: relative;
  height: 60px;
  margin: 0 auto;
  cursor: pointer;
  text-align: center;
  line-height: 60px;
  font-size: 22px;
  background-color: #e7e7e7;
  color: #565656;
  display: none;
  
  &.active {
    display: block;
  }
  
  .close {
    color: #ffffff;
    font-size: 24px;
    position: absolute;
    right: 20%;
    top: 5px;
    
    &:hover {
      color: #f3f3f3;
    }
  }
}

.Heard {
  width: 1440px;
  height: 60px;
  //box-shadow: 0 4px 4px 0 rgba(231, 242, 250, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  
  .location-container {
    display: flex;
    align-items: baseline;
    cursor: pointer;
    i {
      color: #fc3737;
      font-size: 18px;
    }
    > * + * {
      margin-left: 8px;
      font-weight: bold;
    }
  }

  
  .Heard-flex {
    display: flex;
  }
  
  .Info {
    width: 1440px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    .dream {
      font-size: 19px;
      color: red;
      font-weight: bold;
      letter-spacing: 4px;
       margin-left: -320px;
      margin-top: 24px;
    }
    
    .img {
      height: 50px;
      cursor: pointer;
    }
    .Search {
      height: 40px;
    }
  }
}

.IsLogin {
  display: flex;
  align-items: center;
  
  div {
    color: #888888;
    font-size: 18px;
    cursor: pointer;
    
    &:hover {
      color: #fc3737 !important;
    }
  }
  
  .sep {
    color: #b0b0b0;
    font-size: 12px;
    margin: 0 5px;
  }
}
</style>
